import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Linking, Dimensions, TouchableOpacity, ImageBackground } from 'react-native';
import { material } from 'react-native-typography';
import MobileStoreButton from 'react-mobile-store-button';
import meepaisa_logo from '../src/assets/meepaisa_logo.png';
import Header from './header';

export default function Home() {
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");

  const [dimensions, setDimensions] = useState({ window, screen });
  const iOSUrl = 'https://apps.apple.com/in/app/mee-delivery/id1631051036';
  const androidUrl = 'https://play.google.com/store/apps/details?id=com.delivery.meepaisa';
  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setDimensions({ window, screen });
      }
    );
    return () => subscription?.remove();
  });
  return (
    <ImageBackground source={meepaisa_logo} resizeMode="cover" style={styles.image}>
      <Header />
      <View style={[styles.container, { flexDirection: "column" }]}>
        <View style={{ flexDirection: "row", alignItems: 'center' }}>
        </View>
        <View style={[styles.fixToText]}>
          <MobileStoreButton
            width={200}
            store="ios"
            url={iOSUrl}
            linkProps={{ title: 'iOS Store Button' }}
          />
          <MobileStoreButton
            width={220}
            height={100}
            store="android"
            url={androidUrl}
            linkProps={{ title: 'google play Store Button' }}
          />         
        </View>
        <View style={{flex:1,position: 'absolute',top:"70%"}}>
        <Text style={styles.title}>
            This Application is for delivering the meepaisa products and if you are existing delivery agent or do you want to become as meepaisa delivery agent please install our mobile apps.
          </Text>
          </View>
        <View style={{ flex: 3 }}>
          <View style={styles.footer}>
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <View>
                <Text style={{ fontWeight: "bold", color: "white", fontSize: 20 }}
                  onPress={() => {
                    Linking.openURL("https://www.easystepin.com/").catch((err) =>
                      console.error('An error occurred', err));
                  }}>
                  ABOUT OUR WEBSITE
                </Text>
              </View>
              <View
                style={{
                  height: 2,
                  width: "70%",
                  backgroundColor: 'white',
                }}
              />
              <View style={{ flexDirection: "row" }}>
                <View style={{ padding: 10 }}>
                  <TouchableOpacity style={styles.button} onPress={() => {
                    Linking.openURL("PrivacyPolicy").catch((err) =>
                      console.error('An error occurred', err));
                  }}>
                    <Text style={{ color: "white", fontSize: 15, fontWeight: "bold" }}>Privacy policy </Text>
                  </TouchableOpacity>
                </View>
                <View style={{ padding: 10 }}>
                  <TouchableOpacity style={styles.button} onPress={() => {
                    Linking.openURL("TermsAndConditions").catch((err) =>
                      console.error('An error occurred', err));
                  }}>
                    <Text style={{ color: "white", fontSize: 15, fontWeight: "bold" }}>Terms and Conditions </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <Text            
               style={[material.caption, { fontWeight: "bold", color: "white" }]}>{'Copyright © '}
                EasyStepIn
                {' '}
                {new Date().getFullYear()}
                {'.'}
              </Text> </View>
          </View>
        </View>
      </View> </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 2,
  },
  footer: {
    position: 'absolute',
    flex: 0.1,
    left: 0,
    right: 0,
    bottom: -10,
    backgroundColor: '#27B6CC',
    flexDirection: 'row',
    height: 110,
    alignItems: 'center',
    justifyContent: 'center'
  },
  footerText: {
    color: 'black',
    fontWeight: 'bold',
    alignItems: 'center',
    fontSize: 1
  },
  title: {
    textAlign: 'center',
    justifyContent:'center',
    marginHorizontal: 30,
    fontSize: 18,
    color: 'white',
  },
  fixToText: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 50,
    flexWrap: 'wrap'
  },
  image: {
    flex: 1,
    justifyContent: "center"
  },
});