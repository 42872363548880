/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName} from 'react-native';
import PrivacyPolicy from '../CMS/privacy-policy';
import TermsAndConditions from '../CMS/terms-and-conditions';
import HowCashbackWorks from '../CMS/HowCashbackWorks'
import Header from '../header';
import Home from '../Home';
import LinkingConfiguration from './LinkingConfiguration';
import { RootStackParamList } from '../../types';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

// export const navigationRef = createNavigationContainerRef();

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
 const Stack = createNativeStackNavigator<RootStackParamList>();
function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Home" component={Home} options={{ headerShown: false }} />    
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} options={{ headerShown: false }} />
      <Stack.Screen name="TermsAndConditions" component={TermsAndConditions} options={{ headerShown: false }} />
      <Stack.Screen name="HowCashbackWorks" component={HowCashbackWorks} options={{ headerShown: false }} />
      <Stack.Screen name="Header" component={Header} options={{ headerShown: false }}/>
    </Stack.Navigator>
  );
}

