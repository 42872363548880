import React from "react";
import { Text, Surface, Card } from 'react-native-paper';
import { View, ScrollView, Platform, Dimensions, Image } from 'react-native';
import Header from "../header";
import Video from "../components/video/video";

export default function HowCashbackWorks(props) {

    const { navigation, ...rest } = props;
    const window = Dimensions.get("window");
    const screen = Dimensions.get("screen");
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window').width);
    React.useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({ window, screen }) => {
                setDimensions(window.width);
            }
        );
    });
    return (
        <View style={{ flex: 1 }}>
            <Header navigation={navigation} />
            <ScrollView>
                <Surface>
                    <Text style={{ alignItems: 'center', fontSize: 20, textAlign: 'center' }}>
                        EARN CASHBACK/REWARDS
                    </Text>
                    <Text style={{ alignItems: 'center', fontSize: 20, textAlign: 'center' }}>
                        EVERY TIME YOU SHOP ONLINE
                    </Text>
                    <View >
                        <View style={{ marginBottom: 100, marginTop: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Text >STEP 1</Text>

                            <Image style={{ width: 90, height: 90 }} source={require('../../src/assets/howcashbackworks/login.png')} />
                            <Text>Join / Login to</Text>
                            <Text> meepaisa Cashback/Rewards</Text>
                        </View>
                        <Image style={{ width: 3, height: 100, margin: 5, marginTop: -100, justifyContent: 'center', alignItems: 'center', marginLeft: (Platform.OS === 'web' ? "49%" : "50%") }} source={require('../../src/assets/howcashbackworks/line.png')} />
                        <View style={{ marginBottom: 100, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Text  >STEP 2</Text>
                            <Image style={{ width: 90, height: 90, justifyContent: 'center', alignItems: 'center' }} source={require('../../src/assets/howcashbackworks/partners.png')} />
                            <Text >Click on a Cashback/Rewards</Text>
                            <Text>Deal You Like</Text>

                        </View>
                        <Image style={{ width: 3, height: 100, margin: 5, marginTop: -100, justifyContent: 'center', alignItems: 'center', marginLeft: (Platform.OS === 'web' ? "49%" : "50%") }} source={require('../../src/assets/howcashbackworks/line.png')} />

                        <View style={{ marginBottom: 100, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Text  >STEP 3</Text>

                            <Image style={{ width: 90, height: 90, }} source={require('../../src/assets/howcashbackworks/shop.jpg')} />
                            <Text >Shop at  the Retailer</Text>
                            <Text>the Retailer</Text>
                        </View>
                        <Image style={{ width: 3, height: 100, margin: 5, marginTop: -100, justifyContent: 'center', alignItems: 'center', marginLeft: (Platform.OS === 'web' ? "49%" : "50%") }} source={require('../../src/assets/howcashbackworks/line.png')} />
                        <View style={{ marginBottom: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Text>STEP 4</Text>
                            <Image style={{ width: 90, height: 90 }} source={require('../../src/assets/howcashbackworks/wallet.jpg')} />
                            <Text >Get Cashback/Rewards </Text>
                            <Text>Automatically</Text>

                        </View>
                        <Video navigation={navigation} />

                        <Card
                            style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20, marginTop: 20 }}>
                            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 20 }} >
                                <Image style={{ width: "80%", height: 150 }} source={require("../../src/assets/howcashbackworks/logindesk.png")} />
                            </View>
                            <Image style={{ marginTop: -5, width: 20, height: 20, marginLeft: 8 }} source={require("../../src/assets/howcashbackworks/one.png")} />
                            <Text style={{ marginLeft: 40, marginTop: -60, marginTop: 1, marginBottom: 20 }}>To Browse the offer join/login to Meepaisa search for any products,deals or partners of your choice.</Text>
                        </Card>

                        <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20, marginTop: 20 }}>
                            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 20 }} >
                                <Image style={{ width: "80%", height: 150 }} source={require("../../src/assets/howcashbackworks/deal.jpg")} />
                            </View>
                            <Image style={{ width: 20, height: 20, marginTop: -25, marginLeft: 8 }} source={require("../../src/assets/howcashbackworks/two.png")} />
                            <Text style={{ marginLeft: 40, marginTop: -60, marginTop: 1, marginBottom: 20, }}>Click on a Cashback/Rewards Deal You Like Search for the retailer you want  just click on the 'Activate Cashback/Rewards' button. You will be taken to the retailer's site. Coupon Codes are shown automatically for extra savings.</Text>

                            {/* <Text style={{ marginLeft: 40, marginTop: -60, marginBottom: 20 }}>Click on a Cashback Deal You Like Search for the retailer you want  just click on the 'Activate Cashback' button. You will be taken to the retailer's site. Coupon Codes are shown automatically for extra savings.</Text> */}
                        </Card>

                        <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20, marginTop: 20 }}>
                            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 20 }} >
                                <Image style={{ width: "80%", height: 150 }} source={require("../../src/assets/howcashbackworks/amazonf.png")} />
                            </View>
                            <Image style={{ width: 20, height: 20, marginLeft: 8 }} source={require("../../src/assets/howcashbackworks/three.png")} />
                            <Text style={{ marginLeft: 40, marginTop: -60, marginTop: 1, marginBottom: 20 }}>Shop at the Retailer
                                Shop like you always do. meepaisa Cashback/Rewards will automatically track your purchase & Cashback/Rewards.</Text>
                        </Card>
                        <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20, marginTop: 20 }}>

                            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 20 }} >
                                <Image style={{ width: "80%", height: 150 }} source={require("../../src/assets/howcashbackworks/cashbackf.png")} />
                            </View><Image style={{ width: 20, height: 20, marginLeft: 8 }} source={require("../../src/assets/howcashbackworks/four.png")} />
                            <Text style={{ marginLeft: 40, marginTop: -60, marginTop: 1, marginBottom: 20 }}>Get Cashback/Rewards Automatically
                                Cashback/Rewards is added to your Account in Pending status within a few hours. After the return period ends, Cashback/Rewards status will automatically change to Confirmed.</Text>
                        </Card>
                        <View style={{ marginVertical: 20 }} >
                            <Text style={{ alignItems: 'center', fontSize: 20, textAlign: 'center', fontWeight: 'bold' }}>
                                When you will get cash back?
                            </Text>
                            <Text style={{ alignItems: 'center', fontSize: 15, textAlign: 'center' }}>
                                Click on Cashback/Rewards Deal/Offer/Coupon
                            </Text>
                        </View>
                        <View >
                            <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20 }}>
                                <View style={{ justifyContent: "center", flexDirection: "row" }} >
                                    <Image style={{ width: "80%", height: 260, marginTop: (Platform.OS === 'web' ? 12 : 20), borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", }} source={require("../../src/assets/howcashbackworks/deal111.jpg")} />
                                </View>
                            </Card>

                        </View>
                        <View style={{ marginVertical: 20, alignSelf: "center" }} >
                            <Text style={{ fontWeight: 'bold', fontSize: 12, color: '#0000ff', marginHorizontal: "auto" }}>OR</Text>
                        </View>
                        <View >
                            <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20 }}>
                                <View style={{ justifyContent: "center", flexDirection: "row" }} >
                                    <Image style={{ width: "80%", height: 200, marginTop: (Platform.OS === 'web' ? 50 : 40) }} source={require("../../src/assets/howcashbackworks/offers111.jpg")} />
                                </View>
                            </Card>

                        </View>
                        <View style={{ marginVertical: 20, alignSelf: "center" }} >
                            <Text style={{ fontWeight: 'bold', fontSize: 12, color: '#0000ff', marginHorizontal: "auto" }}>OR</Text>
                        </View>
                        <View>
                            <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20 }}>
                                <View style={{ justifyContent: "center", flexDirection: "row" }} >
                                    <Image style={{ width: "80%", height: 260, marginTop: (Platform.OS === 'web' ? 40 : 30) }} source={require("../../src/assets/howcashbackworks/coupon111.jpg")} />
                                </View>
                            </Card>
                        </View>
                        <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20, marginVertical: 20 }}>
                            <Text style={{ marginTop: (Platform.OS === 'web' ? 20 : 10), fontWeight: 'bold', fontSize: 12, color: '#0000ff' }}>Condition: 1</Text>
                            <Text style={{ marginLeft: 80, marginTop: (Platform.OS === 'web' ? -15 : -18) }}>User should not close the browser.</Text>
                            <Image style={{ width: "80%", height: 180, marginTop: (Platform.OS === 'web' ? 35 : 20), marginLeft: 25 }} source={require("../../src/assets/howcashbackworks/coupon111.jpg")} />
                        </Card>
                        <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20, marginVertical: 20 }}>
                            <Text style={{ marginTop: (Platform.OS === 'web' ? 20 : 10), fontWeight: 'bold', fontSize: 12, color: '#0000ff' }}>Condition: 2</Text>
                            <Text style={{ marginLeft: 80, marginTop: (Platform.OS === 'web' ? -15 : -19) }}>User should order the product within session time out/expired.</Text>
                            <Image style={{ width: "80%", height: 180, marginTop: (Platform.OS === 'web' ? 20 : 20), marginLeft: 25 }} source={require("../../src/assets/howcashbackworks/close.png")} />

                        </Card>
                        <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20, marginVertical: 20 }}>
                            <Text style={{ marginTop: (Platform.OS === 'web' ? 20 : 10), fontWeight: 'bold', fontSize: 12, color: '#0000ff' }}>Condition: 3</Text>
                            <Text style={{ marginLeft: 80, marginTop: -24 }}>Once the user added the product in the cart then the user should place an order within 2 hours</Text>
                            <Image style={{ width: "80%", height: 160, marginTop: (Platform.OS === 'web' ? 20 : 20), marginLeft: 25 }} source={require("../../src/assets/howcashbackworks/threee.png")} />

                        </Card>
                        <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20, marginVertical: 20 }}>
                            <Text style={{ marginTop: (Platform.OS === 'web' ? 20 : 10), fontWeight: 'bold', fontSize: 12, color: '#0000ff' }}>Condition: 4</Text>
                            <Text style={{ marginLeft: 80, marginTop: -18 }}>User should not cancel the order.</Text>
                            <Image style={{ width: "80%", height: 180, marginTop: (Platform.OS === 'web' ? 20 : 20), marginLeft: 25 }} source={require("../../src/assets/howcashbackworks/cancel.png")} />

                        </Card>
                        <Card style={{ shadowOffset: { width: 0, height: 2 }, borderWidth: 1, borderRadius: 10, shadowColor: '#000', borderColor: "#CACFD2", marginHorizontal: 20, marginVertical: 20 }}>
                            <Text style={{ marginTop: (Platform.OS === 'web' ? 20 : 10), fontWeight: 'bold', fontSize: 12, color: '#0000ff' }}>Condition: 5</Text>
                            <Text style={{ marginLeft: 80, marginTop: -18 }}>User should not return the product.</Text>
                            <Image style={{ width: "80%", height: 180, marginTop: (Platform.OS === 'web' ? 20 : 20), marginLeft: 25 }} source={require("../../src/assets/howcashbackworks/return.png")} />

                        </Card>
                    </View>
                </Surface>
            </ScrollView>
        </View>
    );
}