import axios from "axios";
import config from './config';
export const SET_ALL_CONTENTS = "SET_ALL_CONTENTS"
export const CONTENTRESET = "CONTENTRESET";

const headers = config.headersCommon;

export const getAllContentManagementStaticAction = (formData:any, responseFunction:any) => async (dispatch:any) => {
    try {
        let { data } = await axios.post(
            `${config.url}/ContentManagement/Static_Get_Admin`,
            formData,
            {
                headers: headers,
            }
        );
        var Content_Management_Static = data.results[0]
        if (data) {
            responseFunction(Content_Management_Static);
            dispatch({
                type: SET_ALL_CONTENTS,
                payload: Content_Management_Static,
            });
        } else {
            responseFunction({});
        }
    } catch (error) {
        responseFunction({});
    }
};