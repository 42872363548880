import Base64 from "../../hooks/Base64";

const config = {
  // url
  url: "https://node-del.meepaisa.com",

  // auth
  auth: {
    username: "ESICashback@easystepin-6WV1UR",
    password: "b0b963cf-41ee-4240-8767-7c4921b460f8",
  },

  // headersCommon
  headersCommon: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    'Authorization': "Basic " + Base64.btoa("ESICashback@easystepin-6WV1UR:b0b963cf-41ee-4240-8767-7c4921b460f8")
  }
};

export default config;