import { combineReducers } from "redux";
import contentReducer from "./contentReducer";

const reducers = combineReducers({
  allContents: contentReducer,

  
})

export default reducers;
export type RootState = ReturnType<typeof reducers>