import React from "react";
import { View } from "react-native";

export default function Video(props) {
    return (

        <View  >
            <iframe style={{ height: 300,marginTop:40,width:1000, frameborder: 0, scrolling: "no",alignSelf:'center' }} src="https://www.youtube.com/embed/OSRki8oFwQg" ></iframe>
        </View>

    )
}
