import React from 'react';
import { Dimensions, Text, TouchableOpacity, View } from 'react-native';
import { Appbar, Avatar, Surface, TouchableRipple } from 'react-native-paper';
const window = Dimensions.get("window");
const { height, width } = Dimensions.get('window');
const Header = () => {
  return (
    <View>
      <Surface>
        <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: '#27B6CC', height: 70, justifyContent: "space-between" }}>
          <View style={{ flexDirection: 'row', alignItems: "center", justifyContent: 'space-evenly' }}>
          <TouchableRipple>
            <Avatar.Image style={{marginLeft:20}} size={50} source={require('../src/assets/logo2.png')} />         
            </TouchableRipple>
            <TouchableRipple>
              <Appbar.Action onPress={()=>{navigation.navigate('Home')}} style={{marginLeft:-30}} size={160} color={'white'} icon={require('../src/assets/logo.png')} />             
            </TouchableRipple>           
          </View>
          <View style={{ flexDirection: "row", justifyContent: "space-evenly", width: 400, }}>
            <TouchableOpacity onPress={() => navigation.navigate('HowCashbackWorks')}>
              <Text style={{ fontSize: 18, fontWeight: 'bold', color: '#fff' }}>How meepaisa works?</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Surface>
    </View>
  );
};

export default Header;
