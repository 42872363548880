import * as React from 'react';
import { LogBox } from 'react-native';
import { Provider } from "react-redux";
import useCachedResources from './src/hooks/useCachedResources';
import useColorScheme from './src/hooks/useColorScheme';
import Navigation from './src/navigations/index'
import store from './src/store';

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  LogBox.ignoreAllLogs(true);
  LogBox.ignoreLogs(['Remote debugger']);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <Navigation colorScheme={colorScheme} />
      </Provider>
    );
  }
}